import { useState } from 'react'
import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { media } from '@dfds-ui/theme'
import { useTranslation } from 'react-i18next'
import { Headline } from '@dfds-ui/react-components'

import { Markdown } from '../Markdown'
import { FlexCard } from '../Card'
import { useLocaleContext } from '../LocaleContext'
import mapStyle from './MapStyles'
import { useGooglePlacesAPI } from '@dfds-ui/google-places'

const mapOptions = {
  mapTypeControl: true,
  streetViewControl: true,
  zoomControl: false,
  fullscreenControl: true,
}

const pin = {
  url: 'https://images.ctfassets.net/mivicpf5zews/54O9AiSzwT3bNLKDoEIvlc/b6ba72f77b9dd136ae296443c4d9e5f5/pin.svg',
  scaledSize: {
    width: 40,
    height: 50,
    l: undefined,
    j: undefined,
  },
}

const MapStaticComponent = (props) => (
  <div
    css={css`
      position: relative;
      cursor: pointer;
    `}
    role="button"
    onClick={() => props.onClick()}
    onKeyDown={() => props.onClick()}
    tabIndex={0}
  >
    <img
      src={`https://maps.googleapis.com/maps/api/staticmap?language=${props.language}&scale=2&format=png&maptype=terrain&markers=size:normal%7Cicon:https://images.ctfassets.net/mivicpf5zews/54O9AiSzwT3bNLKDoEIvlc/b6ba72f77b9dd136ae296443c4d9e5f5/pin.svg?fm=png%7C${props?.location?.lat},${props?.location?.lon}&center=${props?.location?.lat},${props?.location?.lon}&zoom=${props.mapZoomLevel}&size=800x400&key=${process.env.GATSBY_GOOGLE_API_KEY}&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x002b45&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:road%7Ccolor:0xe4e4e4&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x002b45%7Cvisibility:on&style=feature:road%7Celement:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:water%7Celement:geometry%7Ccolor:0x0e1626&style=feature:water%7Celement:geometry.fill%7Ccolor:0xc3e0f4&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e`}
      alt={props.title || 'Static Map Image'}
      loading="lazy"
      css={css`
        object-fit: cover;
        height: 100%;
      `}
      id={`map-static-${props.id}`}
    />
    <div
      css={css`
        position: absolute;
        padding: 12px;
        top: 40px;
        left: 50%;
        transform: translate(-50%, 0);
        text-align: center;
        border-radius: 4px;
        background-color: white;
      `}
      id={`map-static-button-${props.id}`}
    >
      {props.placeholder}
    </div>
  </div>
)

const MapLayout = (props) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true)
  const mapZoomLevel = 15
  const styling = mapStyle()
  const {
    sys,
    location,
    title,
    text,
    cardProps,
    markerProps,
    rawtext,
    reverse,
    mapAlignment,
    contentRef,
    ...restMapProps
  } = props
  const { t } = useTranslation()
  const mWidth = reverse || mapAlignment ? { md: 8 } : { md: 12 }
  const { language } = useLocaleContext()
  const { available } = useGooglePlacesAPI()

  async function initMap() {
    let map
    const { Map } = await window.google.maps.importLibrary('maps')
    const { Marker } = await window.google.maps.importLibrary('marker')
    const { StyledMapType } = await window.google.maps.importLibrary('maps')

    let mapDiv = document.getElementById(`map-${sys.id}`)

    function createMapDiv() {
      map = new Map(mapDiv, {
        center: { lat: location.lat, lng: location.lon },
        zoom: mapZoomLevel,
        mapOptions,
        mapTypeIds: ['styled_map'],
        ...restMapProps,
      })
      const marker = new Marker({
        position: { lat: location.lat, lng: location.lon },
        icon: pin,
        markerProps,
      })
      const styledMap = new StyledMapType(styling, { name: 'Styled Map' })

      marker.setMap(map)
      map.mapTypes.set('styled_map', styledMap)
      map.setMapTypeId('styled_map')
    }

    mapDiv !== null && createMapDiv()
  }

  !showPlaceholder && available && initMap()

  return !available ? null : (
    <FlexCard
      reverse={reverse || mapAlignment === 'Right'}
      {...cardProps}
      mediaWidth={mWidth}
      media={
        showPlaceholder ? (
          <MapStaticComponent
            location={location}
            language={language}
            title={contentRef?.title || title}
            placeholder={t('MAPBLOCK-PLACEHOLDERTEXT')}
            onClick={() =>
              setShowPlaceholder((showPlaceholder) => !showPlaceholder)
            }
            id={sys.id}
          />
        ) : (
          <div
            css={css`
              height: 50vh;
              min-height: 490px;
              max-height: 490px;
              width: 100%;

              ${media.greaterThan('xl')`
                    height: 100%;
                    max-height: initial; `}
            `}
          >
            <div
              id={`map-${sys.id}`}
              style={{
                height: '100%',
                width: '100%',
              }}
            />
          </div>
        )
      }
    >
      {(contentRef?.title || title) && (
        <Headline as={'h2'}>{contentRef?.title || title}</Headline>
      )}
      {(contentRef?.text || text) && (
        <Markdown source={contentRef?.text || text} />
      )}
      {contentRef?.rawtext || rawtext}
    </FlexCard>
  )
}

export const query = graphql`
  fragment MapLayout on contentful_MapLayout {
    __typename
    sys {
      id
    }
    location {
      lat
      lon
    }
    mapAlignment
    contentRef {
      title
      text
    }
  }
`

export default MapLayout
